<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Сотрудники</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        :x-small="$vuetify.breakpoint.xs"
        :small="$vuetify.breakpoint.sm"
        color="success"
        dark
        class="mb-2"
        @click="CreateItem"
      >
        Новый сотрудник
      </v-btn>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="form.first_name"
                    label="Имя"
                    :error-messages="FirstNameErrors"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="form.middle_name"
                    :error-messages="MiddleNameErrors"
                    label="Отчество"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="form.last_name"
                    label="Фамилия"
                    :error-messages="LastNameErrors"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="form.email"
                    :error-messages="EmailErrors"
                    label="Email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    prepend-icon="mdi-plus"
                    v-model="form.phone"
                    :error-messages="PhoneErrors"
                    label="Телефонный номер"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="user_department"
                    :items="departments"
                    :error-messages="DepartmentErrors"
                    item-text="name"
                    item-value="id"
                    label="Выберите отдел"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="form.user_access_level"
                    :items="lvl"
                    item-text="name"
                    item-value="value"
                    :error-messages="TypeErrors"
                    label="Выберите тип сотрудника"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="form.password"
                    :error-messages="PasswordErrors"
                    type="password"
                    label="Пароль"
                    v-if="!edit"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="password_confirm"
                    type="password"
                    :error-messages="PasswordConfirmErrors"
                    v-if="!edit"
                    label="Подтвердите пароль"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close"> Отмена </v-btn>
            <v-btn color="success" text @click="save"> Сохранить </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6"
            >Вы уверены, что хотите удалить этого сотрудника?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDelete">Отмена</v-btn>
            <v-btn color="success" text @click="deleteItemConfirm">Да</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog scrollable v-model="dialogTasks" max-width="500px">
        <v-card v-if="selected">
          <v-card-title>
            {{ "Задачи " + selected.first_name + " " + selected.last_name }}
            <v-spacer></v-spacer>
            <v-icon @click="dialogTasks = false"
              >mdi-close</v-icon
            ></v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-list>
              <div v-for="event in selected.tasks" v-bind:key="event.id">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ event.name }} </v-list-item-title>
                    <div class="text--secondary">
                      <span> от {{ event.from_user.first_name }} </span>

                      <p class="mt-3 text--primary">Подробности</p>
                      <truncate
                        clamp="Показать больше"
                        :length="80"
                        less="Показать меньше"
                        type="html"
                        :text="event.details"
                      ></truncate>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-toolbar dense flat>
      <v-tabs v-model="tab" color="success">
        <v-tab
          @change="TabChange(department.name)"
          v-for="department in departments"
          v-bind:key="department.id"
          :href="'#tab-' + department.name"
          >{{ department.name }}</v-tab
        >
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <div v-for="d in departments" v-bind:key="d.id">
        <v-tab-item :value="`tab-${d.name}`">
          <v-data-table
            :headers="headers"
            :items="employees"
            sort-by="salary"
            :loading="x_loading"
            item-class="class"
            :items-per-page="5"
          >
            <template v-slot:item.name="{ item, index }">
              <v-list-item
                :ripple="false"
                @click="editItem(item)"
                @dblclick="deleteItem(item)"
              >
                <v-list-item-avatar size="48">
                  <v-img :src="api + item.image"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ item.first_name }} {{ item.last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <template v-if="index == 0">Hачальник отдела</template>
                    <template v-if="index != 0">Cотрудник</template>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item.phone="{ item }">
              +{{ item.phone }}
            </template>
            <template v-slot:item.tasks="{ item }">
              <v-list-item @click="OpenTasks(item)" :ripple="false">
                <v-list-item-content>
                  {{ item.tasks.length }}
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-data-table>
        </v-tab-item>
      </div>
    </v-tabs-items>
  </div>
</template>

<script>
import { DEPARTMENTS_GET, DEPARTMENT_EDIT } from "@/store/actions/department";
import { USER_REGISTRATION } from "@/store/actions/users";
import { INDIVIDUAL_TASKS_GET } from "@/store/actions/task";
import { required, sameAs, minLength, email } from "vuelidate/lib/validators";
import { Check_email, phone } from "@/utils/validators";

export default {
  data() {
    return {
      tab: "",
      departments: [],
      lvl: [
        { name: "Cотрудник", value: "worker" },
        { name: "Начальник отдела", value: "hod" },
      ],
      dialog: false,
      dialogDelete: false,
      dialogTasks: false,
      edit: false,
      employees: [],
      editedIndex: -1,
      form: {
        first_name: "",
        last_name: "",
        middle_name: "",
        user_access_level: "",
        phone: "",
        email: "",
        password: "",
      },
      user_department: {},
      password_confirm: "",
      defaultItem: {
        name: "",
        salary: 0,
      },
      selected: [],
      api: process.env.VUE_APP_API_URL.slice(0, -1),
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Новый сотрудник"
        : "Редактировать данные о сотруднике";
    },
    FirstNameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push("Имя обязательно");
      return errors;
    },
    LastNameErrors() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push("Фамилия обязательна");
      return errors;
    },
    MiddleNameErrors() {
      const errors = [];
      if (!this.$v.form.middle_name.$dirty) return errors;
      !this.$v.form.middle_name.required && errors.push("Отчество обязательна");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Необходим пароль");
      !this.$v.form.password.minLength &&
        errors.push("Пароль должен содержать не менее 6 символов.");
      return errors;
    },
    PasswordConfirmErrors() {
      const errors = [];
      if (!this.$v.password_confirm.$dirty) return errors;
      !this.$v.password_confirm.sameAsPassword &&
        errors.push("Подтверждение пароля не совпадает");
      !this.$v.password_confirm.required &&
        errors.push("Требуется подтверждение пароля");
      return errors;
    },
    EmailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Должен быть действующий email");
      !this.$v.form.email.required && errors.push("Email обязательна");
      !this.$v.form.email.Check_email &&
        errors.push("Этот email уже существует.");
      return errors;
    },
    PhoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      !this.$v.form.phone.required && errors.push("Номер телефона обязателен");
      !this.$v.form.phone.phone &&
        errors.push("Номер телефона недействителен или уже существует.");
      return errors;
    },
    DepartmentErrors() {
      const errors = [];
      if (!this.$v.user_department.$dirty) return errors;
      !this.$v.user_department.required && errors.push("Требуется отдел");
      return errors;
    },

    TypeErrors() {
      const errors = [];
      if (!this.$v.form.user_access_level.$dirty) return errors;
      !this.$v.form.user_access_level.required &&
        errors.push("Укажите тип сотрудника");
      return errors;
    },

    headers() {
      let headers = [
        {
          text: "ФИО",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Телефонный номер", value: "phone" },
        { text: "Задания", value: "tasks" },
      ];
      switch (this.$store.getters.profile.user_access_level) {
        case "ceo":
          break;
        case "admin":
          break;
        default:
          headers.pop();
      }
      return headers;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  components: {
    tasklist: () =>
      import(
        /* webpackChunkName: "dashboard" */
        "@/components/TaskList"
      ),
  },

  methods: {
    async GetDepartments() {
      let resp = await this.$dispatch(DEPARTMENTS_GET);

      if (resp.success) {
        this.tab = "tab-" + resp.result[0].name;

        this.departments = resp.result;
        for (let d in resp.result) {
          resp.result[d].hod.class = "employee-data-row";
          if (
            this.$store.getters.profile.user_access_level == "ceo" ||
            this.$store.getters.profile.user_access_level == "admin"
          ) {
            let resp2 = await this.$dispatch(
              INDIVIDUAL_TASKS_GET,
              "?status=current_task&to_users=" + resp.result[d].hod.id
            );
            if (resp2.success) {
              resp.result[d].hod.tasks = resp2.result;
            }
          }
          for (let w in resp.result[d].workers) {
            resp.result[d].workers[w].class = "employee-data-row";
            if (
              this.$store.getters.profile.user_access_level == "ceo" ||
              this.$store.getters.profile.user_access_level == "admin"
            ) {
              let resp2 = await this.$dispatch(
                INDIVIDUAL_TASKS_GET,
                "?status=current_task&to_users=" + resp.result[d].workers[w].id
              );
              if (resp2.success) {
                resp.result[d].workers[w].tasks = resp2.result;
              }
            }
          }
        }
        this.employees.push(resp.result[0].hod);
        this.employees = this.employees.concat(resp.result[0].workers);
      }
    },

    TabChange(dep) {
      this.employees = [];
      for (let d in this.departments) {
        if (this.departments[d].name == dep) {
          this.departments[d].hod.class = "employee-data-row";
          for (let w in this.departments[d].workers) {
            this.departments[d].workers[w].class = "employee-data-row";
          }
          this.employees.push(this.departments[d].hod);
          this.employees = this.employees.concat(this.departments[d].workers);
        }
      }
    },

    OpenTasks(row) {
      this.selected = this.employees[this.employees.indexOf(row)];
      this.dialogTasks = true;
    },

    editItem(row) {
      this.edit = true;
      if (this.dialogDelete) {
        this.dialogDelete = false;
      }
      this.editedIndex = this.employees.indexOf(row);
      this.form = Object.assign({}, row);
      let department_name = this.tab.split("-")[1];
      let department = this.departments.find(check_departments);
      function check_departments(d) {
        return d.name == department_name;
      }
      this.user_department = department.id;
      this.dialog = true;
    },

    CreateItem() {
      this.edit = false;
      if (this.dialogDelete) {
        this.dialogDelete = false;
      }
      for (var key in this.form) this.form[key] = null;
      this.user_department = null;
      this.dialog = true;
    },

    deleteItem() {
      if (this.dialog) {
        this.dialog = false;
      }
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.employees.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.$v.form.$touch();
      this.$v.user_department.$touch();
      this.$v.password_confirm.$touch();
      if (
        !this.$v.form.$invalid &&
        !this.$v.course.$invalid &&
        !this.$v.password_confirm.$invalid
      ) {
        let resp = await this.$dispatch(USER_REGISTRATION, this.form);
        if (resp.success) {
          if (this.form.user_access_level != "hod") {
            let workers = [];
            workers.push(resp.result.id);
            for (let w in this.user_department.workers) {
              workers.push(this.user_department.workers[w].id);
            }
            let resp2 = await this.$dispatch(DEPARTMENT_EDIT, {
              id: this.user_department.id,
              workers: workers,
            });
            if (resp2.success) {
              this.GetDepartments();
              this.close();
            } else {
              alert(resp2.result);
            }
          }
        } else {
          alert(resp.result);
        }
      }
    },
  },
  created() {
    this.GetDepartments();
  },
  validations() {
    return {
      form: {
        first_name: { required },
        last_name: { required },
        middle_name: { required },
        password: { required, min: minLength(6) },
        phone: { required, phone },
        email: { required, Check_email, email },
        user_access_level: { required },
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs(function() {
          return this.form.password;
        }),
      },
      user_department: { required },
    };
  },
};
</script>

<style lang="scss" scoped></style>>
